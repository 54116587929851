import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/MainLayout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { navigate } from "@reach/router"

export default function EventPost({ data }) {
  const event = data.markdownRemark
  return (
    <Layout>
      <div className="container my-5 py-2">
        <div className="my-5 d-flex flex-column align-items-center">
          <h1 className="fw-bold text-center">{event.frontmatter.title}</h1>
          <div className="underline"></div>
          {/* <p className="fw-bold my-2 fs-5 text-muted">{new Date(event.frontmatter.date).toDateString()}</p> */}
        </div>
        <div className="row">
          <div className="col-lg order-lg-2 posterContainer">
            <GatsbyImage
              image={getImage(event.frontmatter.image)}
              className="poster rounded-xl"
              alt={event.frontmatter.title}
            />
          </div>
          <div
            className="col-lg order-lg-1 my-5 p-4 contentSection"
            dangerouslySetInnerHTML={{ __html: event.html }}
          />
        </div>
        <button
          className="btn btn-primary mx-2 fw-bold"
          onClick={() => navigate(-1)}
        >
          <i className="bi bi-arrow-left px-1"></i>Go Back
        </button>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        slug
        title
        date
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      html
      id
    }
  }
`
export async function config() {
  const { data } = graphql`
    {
      oldPosts: allMarkdownRemark(
        filter: { frontmatter: { date: { lt: "2022-01-01" } } }
      ) {
        nodes {
          frontmatter {
            slug
          }
        }
      }
    }
  `
  const oldPosts = new Set(data.oldPosts.nodes.map(n => n.frontmatter.slug))
  return ({ params }) => {
    return {
      defer: oldPosts.has(params.frontmatter__slug),
    }
  }
}
